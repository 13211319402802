/** @jsx jsx */
import React from "react"
import { jsx, Box, Text } from 'theme-ui'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ProjectsPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Projects" />
        <Box sx={{mb: [8, 9, 12]}}>
          <Text as='h1' variant='h1' sx={{maxWidth: '20em', mb: 6}}>
            Projects & Ideas
          </Text>
          <Text variant='h4' sx={{maxWidth: '800px'}}>
            Some of my side projects, rough ideas
          </Text>
        </Box>
      </Layout>
    )
  }
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
